import { combineReducers } from '@reduxjs/toolkit';

import { joinRequestsReducer } from './join-requests';
import { loadingReducer } from './loading';
import { notificationsReducer } from './notifications';
import { pendingInvitesReducer } from './pending-invites';
import { productsReducer } from './products';
import { subscriptionsReducer } from './subscriptions';
import { currentTeamReducer } from './current-team';
import { userReducer } from './user';
import { profileReducer } from './profile';
import { teamsReducer } from './teams';
import { groupsReducer } from './groups';
import { swimDataReducer } from './swim-data';
import { chartReducer } from './chart';
import { licensesReducer } from './licenses';
import { invitesReducer } from './invites/reducer';
import { commentReducer } from './comment/reducer';
import { dashboardReducer } from './dashboard/reducer';
import { chartVideoReducer } from './video-chart/reducer';
import { shareAccessReducer } from './shared-access/reducer';
import { thresholdReducer } from './threshold/reducer';

const reducers = combineReducers({
  user: userReducer,
  profile: profileReducer,
  products: productsReducer,
  groups: groupsReducer,
  subscriptions: subscriptionsReducer,
  pendingInvites: pendingInvitesReducer,
  // Adding a global loading state for used by the components or functions without state
  // like the one in /components/accept-invites
  loading: loadingReducer,
  notifications: notificationsReducer,
  currentTeam: currentTeamReducer,
  joinRequests: joinRequestsReducer,
  // This is different from currentTeamReducer as this is for listing of all teams
  // but currently this is only being used to store data for teams that you don't belong to.
  teams: teamsReducer,
  swimData: swimDataReducer,
  chart: chartReducer,
  licenses: licensesReducer,
  invites: invitesReducer,
  comments: commentReducer,
  dashboard: dashboardReducer,
  videoChart: chartVideoReducer,
  sharedAccess: shareAccessReducer,
  threshold: thresholdReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'user/signOutUser') {
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
