import { createAsyncThunk } from '@reduxjs/toolkit';
import { catchErrors } from '../notifications';
import { API_ENDPOINTS, ThresholdPayload } from '../../types/threshold';
import apiPost from '../../utils/api-post';

export const createThreshold = createAsyncThunk(
  'threshold/createThreshold',
  async (payload: ThresholdPayload, { rejectWithValue, dispatch }) => {
    try {
      return await apiPost(API_ENDPOINTS.POST_THRESHOLD, payload);
    } catch (e: any) {
      dispatch(catchErrors(e));
      throw rejectWithValue(e);
    }
  }
);