import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import apiGet from '../../utils/api-get';
import apiPatch from '../../utils/api-patch';
import { computeRemainingValidity } from '../../utils/license';

import { LICENSES_ENDPOINT } from '../../constants/api-endpoints';

import { addNotification, catchErrors } from '../notifications';

import { License, LinkToTeamDetails } from '../../types/license';

const LICENSE_TYPE = 'licenses';

export const updateInvitingLicenseId = createAction<string>(
  `${LICENSE_TYPE}/updateInvitingLicenseId`
);

export const updateShowAddLicenseToTeamModal = createAction<boolean>(
  `${LICENSE_TYPE}/updateShowAddLicenseToTeamModal`
);

export const setLinkToTeamLicense = createAction<LinkToTeamDetails>(
  `${LICENSE_TYPE}/linkToTeamLicense`
);

export const fetchOwnedLicenses = createAsyncThunk<License[], string>(
  `${LICENSE_TYPE}/fetchOwnedLicenses`,
  async (licenseOwnerId: string, { dispatch, rejectWithValue }) => {
    try {
      const licenses = await apiGet(`${LICENSES_ENDPOINT}/user`, {
        licenseOwnerId,
      });

      const currentDate = new Date();
      // Setting the hours of the day to 0,0,0,0 which is 12 midnight
      // To compute the remaining days by days alone excluding time
      currentDate.setHours(0, 0, 0, 0);

      return licenses.map((license: License) =>
        computeRemainingValidity(license, currentDate)
      );
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const fetchAssignedLicenses = createAsyncThunk<License[], string>(
  `${LICENSE_TYPE}/fetchAssignedLicenses`,
  async (userId: string, { dispatch, rejectWithValue }) => {
    try {
      const licenses = await apiGet(`${LICENSES_ENDPOINT}/assigned-to-user`, {
        userId,
      });

      const currentDate = new Date();
      // Setting the hours of the day to 0,0,0,0 which is 12 midnight
      // To compute the remaining days by days alone excluding time
      currentDate.setHours(0, 0, 0, 0);

      return licenses.map((license: License) => {
        return computeRemainingValidity(license, currentDate);
      });
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const assignedSelfToLicense = createAsyncThunk(
  `${LICENSE_TYPE}/assignedSelfToLicense`,
  async (licenseId: string, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      await apiPatch(
        `${LICENSES_ENDPOINT}/assigned-self?licenseId=${licenseId}`
      );
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const unassignLicense = createAsyncThunk(
  `${LICENSE_TYPE}/unassignLicense`,
  async (
    payload: { licenseId: string; serialNo: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await apiPatch(
        `${LICENSES_ENDPOINT}/unassigned?licenseId=${payload.licenseId}`
      );

      // dispatch(
      //   addNotification({
      //     message: `Licence number ${payload.serialNo} is now free`,
      //     type: 'success',
      //   })
      // );
      return response;
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const cancelLicense = createAsyncThunk(
  `${LICENSE_TYPE}/cancelLicense`,
  async (licenseId: string, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    try {
      const result = await apiPatch(
        `${LICENSES_ENDPOINT}/cancel?licenseId=${licenseId}`
      );
      return result;
    } catch (errors: any) {
      dispatch(catchErrors(errors));
      throw rejectWithValue(errors);
    }
  }
);

export const fetchMembershipExpired = async (userId: string) => {
  try {
    const result = await apiGet(
      `${LICENSES_ENDPOINT}/membership-expired?userId=${userId}`
    );

    return result;
  } catch (errors: any) {
    // Handle errors as needed
    throw errors;
  }
};