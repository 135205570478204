import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { Threshold } from '../../types/threshold';
import { ReduxState } from '../../types/redux-state';

import { createThreshold } from './actions';


interface ThresholdState extends ReduxState {
    threshold: Threshold;
  }

const initialState: ThresholdState = {
    threshold: {
      functionThresholdPower : 0,
      criticalSwimSpeed: 0,
    },
    isLoading: false,
    errors: [],
  };

  export const thresholdReducer = createReducer(initialState, (builder) => {
    builder
    .addCase(createThreshold.fulfilled, (state, { payload }) => {
      state.threshold = { ...payload };
    })
    .addMatcher(
        isAnyOf(
          createThreshold.pending,
        ),
        (state) => {
          state.isLoading = true;
          state.errors = [];
        }
      )
      .addMatcher(
        isAnyOf(
          createThreshold.fulfilled,
          createThreshold.rejected,
        ),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          createThreshold.rejected
        ),
        (state, { payload }) => {
          state.errors = payload as string[];
        }
      );
  });
