import axios from 'axios';

export const getLocale = async (locale: string) => {

  var lang = locale.startsWith('en') ? 'en-US' : locale;
  lang = lang.startsWith('pt') ? 'pt' : lang;
  lang = lang.startsWith('fr') ? 'fr' : lang;
  lang = lang.startsWith('de') ? 'de' : lang;
  lang = lang.startsWith('it') ? 'it' : lang;
  lang = lang.startsWith('es') ? 'es' : lang;
  lang = lang.startsWith('nl') ? 'nl' : lang;
  lang = lang.startsWith('sv') ? 'sv' : lang;
  lang = lang.startsWith('zh') ? 'zh' : lang;
  lang = lang.toLocaleLowerCase();

  switch (lang) {
    case 'de':
    case 'es':
    case 'fil':
    case 'fr':
    case 'it':
    case 'ja':
    case 'nl':
    case 'pl':
    case 'pt':
    case 'sv':
    case 'zh':
      break;
    default:
      lang = 'en-US'
      break;
  }

  let apiEndPoint = `${process.env.API_ENDPOINT}localization?language=`;
  const _locale = lang.split('-').join('_');

  const res = await axios.get(`${apiEndPoint}${_locale}`, {
    headers: {
      'EOP-SwimClip-Key': 'swimde10af97-5edc-4869-a70b-423c5d7cbc5eclip',
    },
  });

  return res.data;
};

export const getLocalelanguage = () => {

  const locale = navigator.language;

  var lang = locale.startsWith('en') ? 'en-US' : locale;
  lang = lang.startsWith('pt') ? 'pt' : lang;
  lang = lang.startsWith('fr') ? 'fr' : lang;
  lang = lang.startsWith('de') ? 'de' : lang;
  lang = lang.startsWith('it') ? 'it' : lang;
  lang = lang.startsWith('es') ? 'es' : lang;
  lang = lang.startsWith('nl') ? 'nl' : lang;
  lang = lang.startsWith('sv') ? 'sv' : lang;
  lang = lang.startsWith('zh') ? 'zh' : lang;
  lang = lang.toLocaleLowerCase();

  switch (lang) {
    case 'de':
    case 'es':
    case 'fil':
    case 'fr':
    case 'it':
    case 'ja':
    case 'nl':
    case 'pl':
    case 'pt':
    case 'sv':
    case 'zh':
      break;
    default:
      lang = 'en-US'
  }

  const _locale = lang.split('-').join('_');

  return _locale;
};
