export type Threshold = {
  functionThresholdPower?: number,
  criticalSwimSpeed?: number
};

export type ThresholdPayload = {
  test400m: number;
  test200m: number;
  test400mDistance: number;
  test200mDistance: number;
};

export const API_ENDPOINTS = {
  POST_THRESHOLD: 'threshold/calculate-critical-speed',
};